import { Slot } from '@wix/ambassador-availability-calendar/types';
import React from 'react';
import {
  MobileDrawer,
  Card,
  IconButton,
  MobileDrawerPosition,
} from 'wix-ui-tpa';
import { Service } from '../../../../../utils/mappers/service.mapper';
import Text, { TextType } from '../../../Text/Text';
import { ModalContent } from '../ModalContent/ModalContent';
import { ButtonProp, ModalFooter } from '../ModalFooter/ModalFooter';
import { classes, st } from './MobileModal.st.css';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { MobileModalDataHooks } from './dataHooks';
import { ServicePaymentDetails } from '../../../../../types/types';

export interface MobileModalProps {
  slot: Slot;
  service: Service;
  dataHook: string;
  dateRegionalSettingsLocale: string;
  primaryButton: ButtonProp;
  secondaryButton: ButtonProp;
  isOpen: boolean;
  isRTL?: boolean;
  onClose: () => {};
  title: string;
  paymentDetails?: ServicePaymentDetails;
}

export const MobileModal: React.FC<MobileModalProps> = ({
  slot,
  service,
  dateRegionalSettingsLocale,
  primaryButton,
  secondaryButton,
  isOpen,
  onClose,
  isRTL = false,
  title,
  dataHook,
  paymentDetails,
}) => (
  <MobileDrawer
    data-hook={dataHook}
    isOpen={isOpen}
    className={st(classes.root, { rtl: isRTL })}
    onRequestClose={onClose}
    position={MobileDrawerPosition.Top}
  >
    <IconButton
      data-hook={MobileModalDataHooks.CLOSE_BUTTON}
      icon={<Close />}
      className={classes.closeButton}
      onClick={onClose}
    />
    <div className={classes.contentWrapper}>
      <Card stacked>
        <Card.Container>
          <Text type={TextType.Title} className={classes.title}>
            {title}
          </Text>
          <ModalContent
            slot={slot}
            service={service}
            dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            paymentDetails={paymentDetails}
          />
        </Card.Container>
        <Card.Container className={classes.footerWrapper}>
          <ModalFooter
            isMobile
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
          />
        </Card.Container>
      </Card>
    </div>
  </MobileDrawer>
);
